<template>
  <section ref="me">
    <div class="box" :class="panelBorderClass">
      <div class="box-header">
        <DashboardPanelTitle
          :panel="panel"
          :connector="equipment"
          :isEditing="isEditing"
          @click.stop.prevent="$emit('panelProperties')"
        />
        <slot name="toolbar"></slot>
        <template v-if="isEditing">
          <portal to="panel-editor-toolbar">
            <SynopticEditorToolbar v-if="isEditing" />
          </portal>
        </template>
      </div>
      <div class="box-body box-body-synotic" ref="synoptic" :style="bodyStyle">
        <SynopticDisplay
          ref="display"
          :equipment="equipment"
          :display="display"
          :mode="mode"
          :panel="panel"
          :isEditing="isEditing"
          :isPanelLocked="isPanelLocked"
          :isLinked="isLinked"
          :key="restoreCounter"
        />
      </div>
      <Spin v-if="!ready" />
    </div>
  </section>
</template>

<script>
import Spin from "@/components/spin";
import SynopticDisplay from "@/components/synoptic/synoptic-display.vue";
import SynopticEditorToolbar from "@/components/synoptic/synoptic-editor-toolbar.vue";
import DashboardPanelTitle from "@/components/dashboard-panel-title.vue";
export default {
  name: "SynopticPanel",
  components: {
    SynopticDisplay,
    Spin,
    SynopticEditorToolbar,
    DashboardPanelTitle
  },
  data() {
    return {
      area: 0,
      refreshTimer: null,
      ready: true,
      referenceIds: null,
      restoreCounter: 0
    };
  },
  props: {
    equipment: { type: Object, required: false, default: () => ({}) },
    panel: { type: Object, required: true },
    display: { type: Object, required: false },
    panelName: { type: String, required: true },
    mode: { type: String, required: true, default: "viewer" },
    screenId: { type: [String, Number], required: false, default: () => 0 },
    title: { type: String, required: true },
    isEditing: { type: Boolean, required: false, default: () => false }
  },
  computed: {
    panelBorderClass() {
      if (window.location != window.parent.location && !this.panel.iframeTitle)
        return "embedded";
      return [
        this.isEditing ? "box-warning" : "box-primary",
        this.fitToPage ? "box-fit-to-page" : "",
        this.noTitle ? "no-title" : ""
      ];
    },
    panelOptions: function () {
      var panel = this.panel || null;
      return (panel && panel.options) || null;
    },
    dashboardDraftPanel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    panelStyle() {
      return this?.panel?.style || {};
    },
    panelToolBar() {
      return this.panel.toolbar;
    },
    bodyStyle() {
      if ("background-color" in this.panelStyle) {
        return {
          "background-color": this.panelStyle["background-color"]
        };
      }
      return {};
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"];
    },
    isPanelLocked() {
      return this.mode == "editor" && this.panel.locked;
    },
    fitToPage() {
      return (
        this.mode == "viewer" && (this?.panel?.options?.fitToPage ?? false)
      );
    },
    noTitle() {
      return (
        this.fitToPage &&
        this.$utils.trim(this.title) === "" &&
        this.panel.toolbar.length == 0
      );
    },
    linkedScreen() {
      return (this.display.linkedPanels || {})[this.panel.name] ?? null;
    },
    isLinked() {
      return this.linkedScreen ? true : false;
    },
    isSyncEnabled() {
      return (this.linkedScreen && this.linkedScreen.syncEnabled) || false;
    }
  },
  watch: {
    display: {
      handler(n, o) {
        if (this.ready) {
          if (n && o && n.id && o.id && n.id != o.id) {
            this.ready = false;
            this.$nextTick(() => {
              this.ready = true;
            });
          }
        }
      },
      deep: true
    },
    isEditing: {
      handler(n, o) {
        if (!this.mode == "editor") return;
        if (n) {
          if (!o) {
            this.$store.dispatch("synoptic/setupPanel", {
              id: this.screenId,
              panel: this.panel
            });
            // this.$emit("initCustomProperties", {
            //   panelName: this.panelName,
            //   propertyEditor: SynopticPropertyEditor
            // });
          }
          // this.$root.$emit("panelFocus");
          this.$root.$emit("editor.keyboard:focus");
        } else if (o) {
          let panelName = this.$store.getters["synoptic/panel"]?.name ?? "";
          if (panelName && panelName == this.panelName) {
            this.$store.dispatch("synoptic/resetState");
          }
        }
      },
      immediate: true
    },
    // title(n) {
    //   // Important: This function sync properties that are not managed by synoptic.
    //   this.$nextTick(() => {
    //     if (!this.isEditing) return;
    //     // this.$store.dispatch("synoptic/setPanelProperties", { name: this.panelName, title: n });
    //   });
    // },
    panelStyle(n) {
      // Important: This function sync properties that are not managed by synoptic.
      this.$nextTick(() => {
        if (!this.isEditing) return;
        this.$store.dispatch("synoptic/setPanelProperties", {
          name: this.panelName,
          style: { ...n }
        });
      });
    },
    panelToolBar(n) {
      // Important: This function sync properties that are not managed by synoptic.
      this.$nextTick(() => {
        if (!this.isEditing) return;
        this.$store.dispatch("synoptic/setPanelProperties", {
          name: this.panelName,
          toolbar: [...n]
        });
      });
    }
  },
  methods: {
    onUpdateParams(params) {
      (this?.panelOptions?.controls || [])
        .filter(
          (ctrl) =>
            ctrl.enabled &&
            ctrl.synopticComponent &&
            ctrl.name in (params || {})
        )
        .forEach((ctrl) => {
          var dataId = params[ctrl.name]?.data_id || params[ctrl.name] || "";
          if (dataId != ctrl.data_id) {
            console.log(dataId);
            this.$set(ctrl, "data_id", dataId);
            if (
              !(this.$store.getters["dashboard/dataList"] || []).some(
                ({ id }) => id == dataId
              )
            ) {
              this.$store.dispatch("dashboard/fetchResources", {
                resource: "data",
                list: [dataId],
                forceUpdate: true
              });
            }
          }
        });
    },
    setupEmbeddedPage() {
      // this method should only be called while in embedded mode (iframe).
      if (window.location == window.parent.location) return;
      window.onmessage = (e) => {
        if (e.data && typeof e.data == "object" && "hi_msg" in (e.data || {})) {
          let params = e?.data?.hi_msg?.action?.options?.params || {};
          this.onUpdateParams(params);
        }
      };
      window.top.postMessage({ hi_msg: "NACK", from: window.name }, "*");
    }
  },
  created() {
    if (this.mode == "editor") return;
    if (window.location !== window.parent.location) {
      this.setupEmbeddedPage();
    }
  },
  mounted() {
    let self = this;
    document.addEventListener("visibilitychange", () => {
      if (
        this.ready &&
        !document.hidden &&
        this.$refs.display &&
        this.$refs.display.hasDirtyControls &&
        !this.$refs.display.hasDirtyControls()
      ) {
        self.restoreCounter += 1;
      }
    });
  }
};
</script>

<style scoped>
.box {
  margin: 0;
  padding: 0;
  box-shadow: none;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.box > .box-header {
  padding: 0 5px;
  display: flex;
}

.embedded {
  border-top-width: 0;
  margin-top: -20px;
}

.nav-tabs-custom {
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.box-body-synotic {
  padding: 0;
}

.box-header > span {
  padding: 0 10px 0 2px;
  font-size: 20px;
}

.fa-redo {
  transform: rotateY(180deg);
}

.clicable-title:hover {
  cursor: pointer;
  opacity: 0.8;
  color: #31708f;
}

.box-fit-to-page {
  border: none;
}

.box-fit-to-page.no-title > div.box-header {
  display: none;
}
</style>

<style lang="scss" scoped>
@media print {
  .no-print {
    display: none;
  }
}
</style>
