var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isReady)?_c('div',{staticClass:"box-tools"},[_c('span',{staticClass:"btn btn-box-tool",class:{ disabled: !_vm.canUndo },staticStyle:{"font-size":"0.9em"},attrs:{"disabled":!_vm.canUndo,"title":_vm.$t('undo_action')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClick('undo')}}},[_c('i',{staticClass:"fa fa-reply fa-undo"})]),_c('span',{staticClass:"btn btn-box-tool",class:{ disabled: !_vm.canRedo },staticStyle:{"font-size":"0.9em"},attrs:{"disabled":!_vm.canRedo,"title":_vm.$t('redo_action')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClick('redo')}}},[_c('i',{staticClass:"fa fa-reply fa-redo"})]),_c('span',{staticClass:"btn btn-box-tool fa",class:_vm.isPanelLocked ? 'fa-lock is-active' : 'fa-unlock',staticStyle:{"font-size":"1.1em"},attrs:{"title":`${this.$t(
      `synoptic.${this.isPanelLocked ? 'unlock' : 'lock'}`
    )} ${this.$t('controls').toLowerCase()}`},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClick('toggleLockControls')}}}),_c('span',{staticClass:"btn btn-box-tool fa fa-hand-pointer-o",class:_vm.areControlsSelectable ? 'is-active' : '',staticStyle:{"padding-top":"4px"},attrs:{"title":_vm.$t(
        `synoptic.${
          _vm.areControlsSelectable ? 'disable_select_mode' : 'enable_select_mode'
        }`
      )},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClick('toggleSelectToChange')}}})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }